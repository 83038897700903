.timeline {
    background-image: 
    /* linear-gradient(
        to left,
        rgba(11, 238, 238, 0.504),
        rgba(10, 107, 102, 0.999)
      ), */
      url("../../assets/AIT1.png");
    /* height: auto; */
    width: 100%;
    /* background-size: contain; */
    background-size: cover;
  
    background-size: 100% 100%;
  
    background-repeat: no-repeat;
  }