.image-container {
    /* width: 200px;
    height: 200px; */
    position: relative;
    overflow: hidden;
  }
  
  .image-container img {
    /* width: 100%;
    height: 100%; */
    transition: transform 0.3s;
  }
  
  .image-container:hover img {
    transform: rotate(360deg);
  }