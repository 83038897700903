.newslanding{
    background-image:
     /* linear-gradient(
      to left,
      rgba(2, 1, 9, 0.01),
      rgba(3, 3, 3, 0.9)
    ), */
    url("../../assets/concerns.png");
    height: auto;
  width: 100%;
  /* background-size: contain; */
  background-size: cover;

  background-size: 100% 85%;

  background-repeat: no-repeat;
}


/* .title {*
  font-family: "Cairo";
  text-align: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  letter-spacing: 1px;
  line-height:2
}

h2 {
  background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
  background-size: cover;
  color: transparent;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  font-size: 120px;
  margin: 10px 0;
} */
/* styling my button */

 

 