.contactlanding{
    background-image: 
    linear-gradient(
        to left,
        rgba(3, 18, 21, 0.521),
        rgba(3, 29, 34, 0.9)
      ),
      url("../../assets/conatct.jpg");
    height: auto;
    width: 100%;
    /* background-size: contain; */
    background-size: cover;
  
    background-size: 100% 85%;
  
    background-repeat: no-repeat;


}